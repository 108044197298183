<template>
  <div>
    <!-- 导航栏 -->
    <div id="app">
      <Ztl />
    </div>

    <!-- 新增图片展示区 -->
    <div class="image-gallery">
      <img src="../assets/gywm/gywm.png" alt="Image 1 Description">
    </div>
    <!-- 关于凌空物语 -->
    <div class="zzzs-section">
      <h2>
        <strong class="guanyulingkongwuyu">
          <span class="title-text">关于</span>
          <span class="highlighted-text">凌空物语</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- ABOUT US ----------</span>
      </h2>
    </div>
    <div class="company-info">
      <h2>西藏凌空物语智能科技有限公司</h2>
      <li><strong>西藏凌空物语智能科技有限公司（简称凌空物语）</strong>：是物联网领域高技术企业，公司立足位置信息服务，为合作伙伴提供集硬件、软件、算法的一站式位置服务，涵盖了从产品设计、研发、集成、生产、制造、销售及售后服务全过程、现已申请软件著作十项，并不断有新技术转化为知识产权、拥有多个行业的系统解决方案，已实施于写字楼办公室、物流公司、大型基建工地，凌空物语还在不断挖掘高精度定位系统的潜力，以期为更多行业服务、让每一个位置，每一张图像都发挥价值。</li>


      <h3>服务介绍</h3>
      <h4>一站式位置服务</h4>
      <p>凌空物语智能科技用位置信息链接物联网智慧生态圈，以位置感知技术和aPaaS服务平台为基础，将多维融合定位技术深度融入不同业务体系，覆盖工业智能、工业安全、公检法司和智慧城市四大板块。为客户提供位置感知，定位引擎、定位数据分析挖掘平台及行业、企业示范性应用一体化的技术支持和服务。</p>

      <h3>核心产品</h3>
      <ul>
        <li><strong>厘米级超高精度定位系统</strong>：凌空物语智能科技依托自研的UWB定位技术，力求实现优于5cm的定位精度。搭载智能硬件，由定位基站和定位标签组成。</li>
<!--        <li><strong>蓝牙AOA室内定位系统</strong>：根据复杂场景内不同区域的大小，将蓝牙AOA定位与UWB定位进行系统级融合，配合可兼容两种系统信号的定位标签，打造具有应用价值的融合定位系统。已拥有单基站二维定位、双基站三维定位等系列产品。</li>-->
        <!-- 更多产品信息 -->
      </ul>

      <h3>服务范围</h3>
      <p>可服务行业包括：智慧法院、智慧执法、智能制造、智慧电力、石油化工、隧道施工安全、煤矿/矿井安全、冶金安全、智慧医疗、智慧养老、仓储物流、办公楼宇等应用场景。</p>

      <h3>发展历程</h3>
      <!-- 发展历程列表 -->
      <p>组建UWB技术创始团队。</p>
      <p>致力于矿井无线电人员定位及通信系统开发。</p>
      <p>研制厘米级UWB高精度定位技术，推出“凌空物语”品牌。</p>
      <p>启动位置物联整体解决方案矩阵产品研制。</p>
      <p>西藏凌空物语智能科技有限公司成立。</p>
      <p>营销中心成立，推出写字楼场景位置物联解决方案。</p>
      <p>BLE-zigbee-UWB”多模平台产品研制。</p>
      <h3>团队介绍</h3>
      <li><strong>技术研发团队</strong>：拥有移动通信、雷达、微波电路、云计算与大数据处理等专业领域经验。</li>
      <li><strong>技术营销团队</strong>：3大流程管理体系，创新LTC、IPD、ITR服务和管理流程。</li>
      <li><strong>技术服务团队</strong>：提供1对1技术支持，现场指导安装、调试，确保系统正常工作。</li>

      <p></p>

<!--
      <h3>战略版图</h3>
      <p>西藏凌空物语智能科技有限公司合肥、成都双引擎驱动运营，同时在江苏盐城、常州，福建晋江、山东青岛、深圳设立分部及营销中心，覆盖全国的服务网络，提供全方位的售前售后服务保障。</p>
-->

      <h3>企业使命</h3>
      <p>以位置服务驱动业务，创新为政企业赋能。</p>

      <h3>企业愿景</h3>
      <p>凌空物语智能科技致力于成为国内领先的位置物联网解决方案服务商。</p>

      <h3>企业价值观</h3>
      <p>信任、责任、创新、共赢。</p>

      <h3>资质荣誉</h3>
      <p>国家级科技型中小企业。</p>

      <h3>公司地址</h3>
      <p>西藏自治区拉萨市柳梧新区国际总部城13号楼2F149号。</p>
    </div>




    <!-- 服务合作伙伴 -->
    <div class="zzzs-section">
      <h2>
        <strong class="hangyejiejuefangan">
          <span class="title-text">服务</span>
          <span class="highlighted-text">合作伙伴</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- 智能化位置服务方案 助力企业经济腾飞 ----------</span>
      </h2>
    </div>

    <!-- 新增的合作方图片部分 -->
    <div class="partner-images">
      <img v-for="(partner, index) in partners" :key="index" :src="partner.src" alt="Partner Image">
    </div>




    <!-- 荣誉与专利 -->
    <div class="zzzs-section">
      <h2>
        <strong class="rongyuyuzhuanli">
          <span class="title-text">荣誉</span>
          <span class="highlighted-text">与证书</span>
        </strong>
      </h2>
      <h2>
        <span class="highlighted-text1">---------- ABOUT US ----------</span>
      </h2>
    </div>
    <!-- 荣誉证书图片轮播 -->
    <div class="carousel-container">
      <el-carousel indicator-position="outside" height="350px">
        <el-carousel-item v-for="(imageGroup, groupIndex) in groupedImages" :key="groupIndex">
          <div class="carousel-row">
            <div class="carousel-image-box" v-for="(item, index) in imageGroup" :key="index">
              <img :src="item.src" alt="Image" class="carousel-image">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 底部栏 -->
    <div id="app">
      <Footer />
    </div>
  </div>

</template>

<script>
import Ztl from "@/components/Ztl.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Lianxiwomen",
  components: {
    Footer,
    Ztl,

  },
  computed: {
    groupedImages() {
      const chunkSize = 4;
      let result = [];
      for (let i = 0; i < this.images.length; i += chunkSize) {
        result.push(this.images.slice(i, i + chunkSize));
      }
      return result;
    },
  },
  data() {
    return {
      images: [
        { src: require('../assets/gywm/ryzs1.jpg') },
        { src: require('../assets/gywm/ryzs2.jpg') },
        { src: require('../assets/gywm/ryzs3.jpg') },
        { src: require('../assets/gywm/ryzs4.jpg') },
        { src: require('../assets/gywm/ryzs5.jpg') },
        { src: require('../assets/gywm/ryzs5.jpg') },
        { src: require('../assets/gywm/ryzs6.jpg') },
        { src: require('../assets/gywm/ryzs7.jpg') },

      ],
      partners: [
        { src: require('@/assets/SYhzhb/gjdw.jpg') },
        { src: require('@/assets/SYhzhb/bw.jpg') },
        { src: require('@/assets/SYhzhb/fyjt.jpg') },
        { src: require('@/assets/SYhzhb/gxxwl.jpg') },
        { src: require('@/assets/SYhzhb/shzlzx.jpg') },
        { src: require('@/assets/SYhzhb/zgdt.jpg') },
        { src: require('@/assets/SYhzhb/zghd.jpg') },
        { src: require('@/assets/SYhzhb/zgsy.jpg') },
        { src: require('@/assets/SYhzhb/zgtj.jpg') },
        { src: require('@/assets/SYhzhb/zjky.jpg') },

        // 更多图片
      ],
    }
  },
  created() {

  },
  methods: {}
}
</script>

<style scoped>
.zzzs-section {
  text-align: center;
}
.title-text {
  color: #f64a4a;
  font-size: 35px;
}
.highlighted-text1 {
  color: #4a83fd;
  font-size: 20px;
}
.highlighted-text {
  color: #4a83fd;
  font-size: 35px;
}

.company-info {
  max-width: 1200px; /* 设定最大宽度，可以根据需要调整 */
  margin: 0 auto; /* 水平居中 */
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left; /* 如果需要，可以将文本对齐方式设为左对齐 */
}




.carousel-container {
  width: 100%;
  margin: auto;
}

.carousel-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1px;
}

.carousel-image-box {
  flex: 0 0 calc(16% - 1px);
  text-align: center;
}

.carousel-image {
  max-width: 100%;
  height: auto;
}



.partner-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
  padding: 0 15%;
  transition: all 0.3s ease; /* 添加过渡效果，使变换平滑 */
}

.partner-images img {
  flex-basis: calc(18% - 10px);
  max-width: calc(18% - 10px);
  height: auto;
  transition: transform 0.3s ease; /* 添加过渡效果，使变换平滑 */
}

.partner-images img:hover {
  transform: translateY(-10px); /* 鼠标悬停时图片向上移动5px */
}

/* 当屏幕宽度小于或等于768px时，减少左右空白区域 */
@media (max-width: 768px) {
  .partner-images {
    padding: 0 5%;
  }
}
</style>